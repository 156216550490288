import posts from '../../pages/sample'
import PostItem from "./PostItem";

interface PostProps {
    tag: string,
    isMobile:boolean
}


function PostsLayout(props:PostProps) {

    const handleHover = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        idx: number
    ) => {
        if (e.type === 'mouseenter') {
           // dispatch({ type: ActionType.SELECT, payload: idx });
        } else {
          //  dispatch({ type: ActionType.SELECT, payload: -1 });
        }
    };




    return (
        <>
            <ul className="flex flex-col overflow-y-scroll h-full w-full no-scrollbar">
                {posts.map((post: any, idx) => (
                    <li className='flex flex-row"'
                        key={idx.toString()}
                        onMouseEnter={(e) => handleHover(e, idx)}
                        onMouseLeave={(e) => handleHover(e, idx)}
                    >
                        <PostItem
                            article={post}
                            selected={false}
                            isMobile={props.isMobile}
                        />
                    </li>
                ))}
            </ul>
        </>
    )
}

export default PostsLayout;
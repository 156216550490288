import {ArticleHeader} from "../ArticleHeader";
import {ArticleProps} from "../../../utils/types";
import {FloatingWidget} from "./FloatingWidget";
import {ArticleContent} from "./ArticleContent";
import {cx} from "@vechaiui/react";


export function ArticleView(props:ArticleProps) {


    return (
        <div className="h-full w-full mt-3.5 flex">
            <div  className={cx("no-scrollbar overflow-x-scroll","ml-20","mr-28"
            )}>
                <FloatingWidget/>
                <ArticleHeader article={props.article}/>
                <ArticleContent article={props.article}/>
            </div>
        </div>
    )
}
import {RiMailAddLine} from "react-icons/ri";
import posts from "../../pages/sample"
import {Link} from "react-router-dom";

export const ProfileLayout = () => {
    const followed = false;
    return (
        <>
            <div className="w-full">
                <div>
                    <img
                        className="h-20 w-20 object-cover rounded-full shadow"
                        src="./icons/android-icon-192x192.png"
                        alt=""
                    />
                    <h1 className="font-bold py-4 text-gray-900">
                        AndroiHunt
                    </h1>
                    <h2 className="text-gray-500 text-sm">
                        Android Hunt is tech blog with a collection of android tutorials and libraries. Which is used to make android app development simple.
                    </h2>
                    <div className="py-4 flex items-center space-x-4">
                        <button
                            value={followed ? "Followed" : "Follow"}
                            //onClick={Clickedfollow}
                            className={`${
                                followed
                                    ? "border-[1px] rounded-full border-green-600 hover:border-green-800 duration-500  px-6 py-2 text-gray-900"
                                    : "bg-green-600 rounded-full hover:bg-green-700 duration-500  px-4 py-2 text-white"
                            }`}
                        >
                            {followed ? "Following" : "Follow"}
                        </button>
                        <div
                          //  onClick={ClickedMail}
                            className="h-[37px] w-[37px] cursor-pointer flex justify-center items-center  rounded-full bg-green-600 hover:bg-green-700 duration-500"
                        >
                            <RiMailAddLine className="text-white text-xl" />{" "}
                        </div>
                    </div>
                    <div className="py-4">
                        <h2 className="text-gray-900 text-md font-semibold capitalize">
                            more from android hunt
                        </h2>
                        <div className="py-6">
                            {posts.slice(0, 5)?.map((post:any,index) => (
                                <Link
                                 key={index.toString()+"related"}
                                 to="article">
                                    <div className="flex items-start justify-between py-4">
                                        <div className="space-y-2 flex flex-col jusify-center cursor-pointer">
                                            <div className="flex items-center space-x-2">
                                                {" "}
                                                <img
                                                    alt="r"
                                                    className="h-6 rounded-full"
                                                    src={post.authorimage}
                                                />
                                                <span className="text-sm font-normal capitalize">
                              {post.author}
                            </span>
                                            </div>
                                            <div>
                                                <p className="text-[16px] text-md font-semibold w-[225px]">
                                                    {post.title}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="h-16 w-16 flex justify-start">
                                            <img
                                                className="h-full w-full rounded-md cursor-pointer  object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                                                src={post.image}
                                                alt="image"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="text-xs mt-10">
                  <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Help
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Status
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Writers
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Blog
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Careers
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Privacy
                  </span>
                            <br />
                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Terms
                  </span>

                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    About
                  </span>
                            <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Knowable
                  </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
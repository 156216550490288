import {ArticleProps} from "../../../utils/types";
import {useState} from "react";
export function ArticleContent(props:ArticleProps) {
    const [isopen, setisopen] = useState(false);
    return (
         <article >
            <h1 className="text-[32px] border-b-[1px] font-bold  mb-3 text-black ">
                {props.article.title}
            </h1>
            <h2 className="text-[20px]  font-light text-gray-500">
                {props.article.description}
            </h2>
            {isopen && (
                <div
                    onClick={() => setisopen(false)}
                    className="fixed inset-0 z-40 bg-gray-200/95"
                ></div>
            )}
            <div onClick={() => setisopen((prev) => !prev)}>
                <img
                    className={`${
                        isopen
                            ? "max-h-[600px]  fixed z-50  m-auto inset-x-0 inset-y-0 p-2 bg-white rounded-sm overflow-y-scroll cursor-zoom-out   duration-500 ease-in-out"
                            : "h-auto  cursor-zoom-in my-8 w-full object-cover   duration-500 ease-in-out"
                    }`}
                    src={props.article.image}
                    alt="image"
                />
            </div>
             <div className="prose prose-pre:language-html" dangerouslySetInnerHTML={{ __html: props.article.content }} />
         </article>
    )
}
import Home from './pages/Home';
import {Routes, Route} from "react-router-dom";
import TabLayout from "./components/TabLayout";
import {ArticleView} from "./components/posts/postdetail/ArticleView";
import testData from "./pages/sample";
import React from 'react';
import {AppContextProvider} from "./context/AppContext";
function App() {

  return (
 <AppContextProvider>
     <Routes>
         <Route path="/" element={<Home />}>
             <Route index element={<TabLayout/>} ></Route>
             <Route path="article" element={<ArticleView article={testData[0] as any}/>} />
         </Route>
         <Route path="*" element={<NoFound/>} />
     </Routes>
 </AppContextProvider>
  );

}


export default App;

function NoFound(){
    return (
        <div>
            <h2>404 Not Found</h2>
        </div>
    );
}


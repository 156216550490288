import { Tab } from "@headlessui/react";
import { cx } from "@vechaiui/react";
import PostsLayout from "./posts/PostsLayout";
import {IoIosAdd, IoIosArrowForward} from "react-icons/io";
import {useAppContext} from "../context/AppContext";


const tabs = ["Add","For You", "Following", "UX", "JavaScript", "Photography", "Gadgets", "iOS Development", "Artificial Intelligence", "Travel", "Android Development", "Software Engineering", "Politics", "Art","Navigate"]



function TabLayoutDeskTop(){
    const { selectedTab, onSelectedTab,isMobile } = useAppContext();
    return (
        <div className="h-full w-full  mt-3.5">


            <Tab.Group vertical as="div"  selectedIndex={selectedTab} defaultIndex={selectedTab}
                       className={cx(
                           "w-full"
                       )}
                       onChange={
                           (index:any)=>{
                               if (onSelectedTab) {
                                   onSelectedTab(index)
                               }
                           }
                       }
            >

                <Tab.List as = "div"
                          aria-label="tabs example"
                          className={cx(
                              "border-b border-neutral-200 dark:border-neutral-700 bg-white","flex flex-nowrap   no-scrollbar overflow-x-scroll","ml-28","mr-28"
                          )}
                >
                    {tabs.map((tab,index) => (
                        <Tab
                            key={tab}
                            value={tab}
                            className={({ selected }) =>
                                cx(
                                    'px-4 h-10 py-2 -mb-px text-sm text-center whitespace-nowrap cursor-base"',
                                    'text-gray-500 bg-transparent',
                                    selected
                                        ? 'border-b-2 border-black text-black focus:outline-none'
                                        : 'border-transparent focus:outline-none'
                                )
                            }
                        >
                            {  index==0? (<IoIosAdd size={24}/>):(index==tabs.length-1?(<IoIosArrowForward size={24}/>):(tab))}
                        </Tab>
                    ))}
                </Tab.List>

                <Tab.Panels>
                    {tabs.map((tab) => (
                        <Tab.Panel
                            key={tab}
                            className="fixed h-full w-8/12  overflow-y-scroll pr-28 pl-28"
                        >

                            <PostsLayout isMobile={false}  tag={tab}/>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
function TabLayoutMobile(){
    const { selectedTab, onSelectedTab} = useAppContext();
    return (
        <div className="h-full w-full bg-red-100 mr-2 ml-2">
            <Tab.Group
                vertical as="div"
                selectedIndex={selectedTab}
                onChange={
                    (index:any)=>{
                        if (onSelectedTab) {
                            onSelectedTab(index)
                        }
                    }
                }
                defaultIndex={selectedTab}
                className={cx(
                           "w-full"
                       )}

            >

                <Tab.List as = "div"
                          className={cx(
                              "bg-white","flex flex-nowrap   no-scrollbar overflow-x-scroll"
                          )}
                >
                    {tabs.map((tab,index) => (
                        <Tab
                            key={tab}
                            value={tab}

                            className={cx(
                                    'py-0.5 px-4 mx-1 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100  hover:text-black',
                                    ' focus:text-white focus:bg-black',
                                    selectedTab==index
                                        ? 'text-white bg-black focus:outline-none'
                                        : 'text-gray-500 focus:outline-none'
                                )
                            }
                        >
                            {
                               index==0? (<IoIosAdd size={24}/>):(index==tabs.length-1?(<IoIosArrowForward size={24}/>):(tab))
                            }
                        </Tab>
                    ))}
                </Tab.List>

                <Tab.Panels>
                    {tabs.map((tab) => (
                        <Tab.Panel
                            key={tab}
                            className="fixed h-full w-full  overflow-y-scroll"
                        >
                            <PostsLayout  tag={tab} isMobile={true}/>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}


function TabLayout() {
    const {isMobile } = useAppContext();
    return (
        <div>
            {!isMobile ? (<div >
                <TabLayoutDeskTop/>
            </div>): (
                <div>
                    <TabLayoutMobile/>
                </div>
            )}
        </div>
    )
  }

  export default TabLayout;
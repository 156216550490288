
import {Link} from "react-router-dom";
import {
    HiOutlineLogout
} from "react-icons/hi";
import {Home} from "../icons/Home";
import {BookMark} from "../icons/BookMark";
import {Read} from "./Read";
import {Write} from "../icons/Write";
import {Notification} from "../icons/Notification";
interface PostProps {
    tag?: string,
}

function SidebarLayout(props:PostProps) {
    return (
        <aside className="h-full fixed inset-y-0 z-10 flex flex-shrink-0 w-20  focus:outline-none">
            <nav className="flex flex-col flex-shrink-0 h-full px-2 py-4 border-r dark:border-primary-darker w-full">
                <div className="flex-shrink-0 w-10 h-10 m-5 self-center">
                    <img src="./icons/android-icon-192x192.png" alt="..."
                         className="shadow rounded-full max-w-full h-auto align-middle border-none"/>

                </div>
                <div className="flex flex-col items-center justify-center flex-1 space-y-6">
                    <div
                       className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">Home</span>
                        <Link to="/">
                            <Home/>
                        </Link>
                    </div>
                    <div
                       className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">Notification</span>
                        <Notification/>
                    </div>
                    <div
                       className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">List</span>
                        <BookMark/>
                    </div>
                    <div
                       className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">Stories</span>
                        <Read/>

                    </div>
                    <div className="w-full pl-5 pr-5">
                        <div className="border-b border-gray-300">
                            <div></div>
                        </div>
                    </div>

                    <div
                       className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">Write</span>
                        <Write/>
                    </div>

                </div>
                <form className="flex items-center justify-center flex-shrink-0">
                    <button
                        className="p-2 transition-colors duration-200 rounded-full text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker">
                        <span className="sr-only">Logout</span>
                        <HiOutlineLogout className="w-6 h-6 stroke-1 stroke-gray-500"/>

                    </button>

                </form>


            </nav>
        </aside>
    )
}

export default SidebarLayout;
import { Tooltip } from "react-tippy";
import {BsLinkedin, BsTwitter} from "react-icons/bs";
import {GoStar} from "react-icons/go";
import {GrFacebook} from "react-icons/gr";
import {IoCopy} from "react-icons/io5";
import {ArticleProps} from "../../utils/types";


export function ArticleHeader(props:ArticleProps){
    // @ts-ignore
    // @ts-ignore
    return (
        <div className="wrapper z-0 mt-24 xl:mt-0 flex flex-col justify-center max-w-[800px] px-6">
            <div className="md:flex space-y-8  md:space-y-0 justify-between  items-center space-x-4 py-10 font-extralight text-sm">
                <div className="flex ">
                    <div>
                        <img
                            className="h-12 w-12 object-cover rounded-full"
                            src={props.article.authorimage}
                            alt=""
                        />
                    </div>
                    <div className="pl-4">
                        <p className="font-medium text-base">
                            <span className="text-gray-900">{props.article.author}</span>
                        </p>
                        <div className="flex items-center ">
                            <p className="text-gray-600">
                                {new Date().toDateString()}{" "}
                            </p>
                            <GoStar className="text-gray-500 ml-2 h-4" />
                        </div>
                    </div>
                </div>

                <div className="flex justify-end items-center space-x-6">
                    <div className="border-[0.5px] sm:hidden border-gray-100 w-full"></div>
                    {/* @ts-ignore */}
                    <Tooltip
                        title="Share on Twitter"
                        position="top"
                        trigger="mouseenter"
                        arrow={true}
                        delay={300}
                        hideDelay={0}
                        distance={20}
                    >
                        <BsTwitter className="h-[18px] w-[18px] text-gray-500 hover:text-gray-900 duration-300 cursor-pointer" />
                    </Tooltip>
                    {/* @ts-ignore */}
                    <Tooltip
                        title="Share on Facebook"
                        position="top"
                        trigger="mouseenter"
                        arrow={true}
                        delay={300}
                        distance={20}
                    >
                        <GrFacebook className="h-[17px] w-[17px] text-gray-500 hover:text-gray-900 duration-300 cursor-pointer" />
                    </Tooltip>
                    {/* @ts-ignore */}
                    <Tooltip
                        // options
                        title="Share on Linkedin"
                        position="top"
                        trigger="mouseenter"
                        arrow={true}
                        delay={300}
                        distance={20}
                    >
                        <BsLinkedin className="h-[17px] w-[17px] text-gray-500 hover:text-gray-900 duration-300 cursor-pointer" />
                    </Tooltip>
                    {/* @ts-ignore */}
                    <Tooltip
                        title="Copy Link"
                        position="top"
                        trigger="mouseenter"
                        arrow={true}
                        delay={300}
                        distance={20}
                    >
                        <IoCopy
                           // onClick={copyCodeToClipboard}
                            className="h-[17px] w-[17px] text-gray-500 hover:text-gray-900 duration-300 cursor-pointer"
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
import React, {FC, FunctionComponent, useEffect, useState} from 'react';


type AppPrefs = {
   selectedTab:number,
   isMobile:boolean,
   onSelectedTab? : (index:number)=>void;
};

const defaultViewerContext: AppPrefs = {
   selectedTab:1,
   isMobile:false
}

export const AppContext = React.createContext<AppPrefs>(defaultViewerContext);

export function useAppContext() {
   return React.useContext(AppContext);
}

export const AppContextProvider: FunctionComponent<any> = ({ children }) => {
   const [isMobile, setIsMobile] = useState(defaultViewerContext.isMobile)
   const [selectedTab, setSelectedTab] = useState(defaultViewerContext.selectedTab)
   const handleResize = () => {
      if (window.innerWidth < 720) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
   }
   const onSelectedTab = (index:number) => {
      setSelectedTab(index);
   };
   useEffect(() => {
      if (window.innerWidth < 720) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
      window.addEventListener("resize", handleResize)
   })

   const values = useAppContext();
   return (
       <AppContext.Provider value={{selectedTab:selectedTab,onSelectedTab:onSelectedTab,isMobile:isMobile}}>
          {children}
       </AppContext.Provider>
   );
};



